
/* eslint-disable @typescript-eslint/camelcase */
import { Options, mixins } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Notification from "@/app/ui/components/notification/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import RadioButton from "primevue/radiobutton";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import Checkbox from "primevue/checkbox";
import Loading from "@/app/ui/components/loading/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { VehicleController } from "@/app/ui/controllers/VehicleController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  SttListChecklistData,
  SttListChecklistEntities,
  STTPiecesDetail,
  STTToBePickup
} from "@/domain/entities/PickupManifest";
import { PickupManifestController } from "@/app/ui/controllers/PickupManifestController";
import debounce from "lodash/debounce";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import {
  convertDecimal,
  convertDecimalAfterComma,
  formatDateWithoutTime,
  pastDateFromToday,
  storeDatatoLocalStorage,
  removeDataLocalStorage,
  convertDecimalWithComma
} from "@/app/infrastructures/misc/Utils";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { Pagination } from "@/domain/entities/Pagination";
import JsBarcode from "jsbarcode";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import QRCode from "qrcode";
import {
  SectionManifestData,
  HeaderManifestData
} from "@/domain/entities/MainApp";
import { generateManifest2ColumnPotrait } from "../../modules/manifest-pdf-v3";
import { TrackingController } from "@/app/ui/controllers/TrackingController";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    Notification,
    RadioButton,
    CustomDropdown,
    Checkbox,
    Loading
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
      this.openSuccess = false;
    }
  }
})
export default class CreateFormPickupManifest extends mixins(OutGoingMixins) {
  pagination = {
    page: 1,
    limit: 50
  };
  mounted() {
    this.fetchDrivers();
    this.fetchVehicles("");
    BookingController.getProductsDelivery({
      search: "",
      page: 1,
      limit: 99,
      status: "active"
    });
    this.refs = this.$refs;
  }

  // delete stt pieces
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBePickup.findIndex((data: any) => {
        return (
          data.sttNumber.toUpperCase() === this.sttNumberToDelete ||
          data.sttNumberElexys.toUpperCase() === this.sttNumberToDelete
        );
      });

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = this.errorWordingNotFoundOrDeleted;
      } else {
        this.dataSttToBePickup.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  get detailDistrict() {
    return LocationController.districtDetail;
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get isError() {
    return VehicleController.err;
  }

  // handle stt pieces
  isPaid = true;
  errorSttNumber = "";
  isValidateScan = false;
  onScanSttNumberToDelete(val: any) {
    const splitVal = val.split("#");
    if (splitVal.length > 0) {
      this.sttNumberToDelete = `${splitVal[0]}`;
      this.onDeleteSttNumber();
    } else {
      this.sttNumberToDelete = val;
      this.onDeleteSttNumber();
    }
  }
  onValidateSttNumber = debounce(async (val: any) => {
    if (this.isDisabled) {
      return;
    }
    const splitVal = val.split("#");
    this.form.sttNumber = `${splitVal[0]}`;
    this.isValidateScan = true;
    let getTracking = false;
    const isSttNumber = /^\d{1,2}LP\w+$/g.test(this.form.sttNumber);

    if (!isSttNumber) {
      getTracking = await TrackingController.trackStt({
        sttNo: this.form.sttNumber,
        isGetSla: false
      });
    }
    const sttTrackingData = TrackingController.trackingSttData.sttDetail.sttNo;
    const response = await PickupManifestController.getDetailStt({
      sttNumber: getTracking ? sttTrackingData : this.form.sttNumber
    });
    this.addSttPieceToPickup(response);
  }, 500);
  onChangeInputStt() {
    this.isPaid = true;
  }
  async addSttNumber() {
    if (!this.isDisabled) {
      this.errorSttNumber = "";
      this.isValidateScan = false;
      let getTracking = false;
      const isSttNumber = /^\d{1,2}LP\w+$/g.test(this.form.sttNumber);

      if (!isSttNumber) {
        getTracking = await TrackingController.trackStt({
          sttNo: this.form.sttNumber,
          isGetSla: false
        });
      }
      const sttTrackingData =
        TrackingController.trackingSttData.sttDetail.sttNo;
      const response = await PickupManifestController.getDetailStt({
        sttNumber: getTracking ? sttTrackingData : this.form.sttNumber
      });
      this.addSttPieceToPickup(response);
    }
  }
  addSttPieceToPickup(detailSTT: any) {
    if (detailSTT?.isAllowUpdateStatus) {
      this.onProcessPickupData(detailSTT);
    } else {
      if (!detailSTT?.isAllowUpdateStatus) {
        playNotification("error");
        this.isErrorTyping = true;
        this.errorSttNumber = detailSTT?.errorMessage || detailSTT.message.id;
        return;
      }
      if (this.isValidateScan) this.isErrorValidationScan = true;
      else this.isErrorTyping = true;
      this.errorSttNumber = detailSTT?.errorMessage || detailSTT.message.id;
    }
  }
  onProcessPickupData(detailSTT: any) {
    if (detailSTT.isAllowUpdateStatus && detailSTT.piece !== null) {
      const indexCurrentSttNumber = this.dataSttToBePickup.findIndex(
        (data: any) => {
          return data.sttNumber === detailSTT.sttNumber;
        }
      );
      if (indexCurrentSttNumber > -1) {
        playNotification("error");
        this.errorSttNumber = this.errorWordingAlreadyInput;
        if (this.isValidateScan) this.isErrorValidationScan = true;
        else this.isErrorTyping = true;
        return;
      } else {
        this.isPaid = detailSTT?.isPaid;
        const isSttNumber = /^\d{1,2}LP\w+$/g.test(this.form.sttNumber);

        this.dataSttToBePickup.push(
          new STTToBePickup(
            detailSTT.sttNumber,
            detailSTT.piece,
            detailSTT.product,
            detailSTT.totalPiece,
            detailSTT.grossWeight,
            detailSTT.volumeWeight,
            detailSTT.createdAt,
            detailSTT.origin,
            detailSTT.destination,
            detailSTT.sttNumberElexys,
            isSttNumber ? "-" : this.form.sttNumber
          )
        );
      }
      playNotification("success");
      this.detailSttNotification = {
        sttNumber: detailSTT.sttNumber,
        sttElexysNumber: detailSTT.sttNumberElexys,
        totalPieces: detailSTT.piece.length,
        ...detailSTT.piece[0]
      };
      this.notification = true;
      this.onResetForm();

      // remove notification after 5 second
      setTimeout(() => {
        this.notification = false;
      }, 15000);
    }
  }
  get totalStt() {
    return this.dataSttToBePickup.length;
  }
  get calculatedStt() {
    if (this.inputSttRadioValue === "list") {
      return this.dataSttToBePickup.reduce(
        (a: any, b: any) => {
          return {
            totalPieces: a.totalPieces + b.totalPieces,
            totalGrossWeight: a.totalGrossWeight + b.grossWeight,
            totalVolumeWeight: a.totalVolumeWeight + b.volumeWeight
          };
        },
        {
          totalPieces: 0,
          totalGrossWeight: 0,
          totalVolumeWeight: 0
        }
      );
    }
    return this.dataSttToBePickup.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.sttPieces.length,
          totalGrossWeight:
            a.totalGrossWeight +
            b.sttPieces.reduce((c: any, d: any) => {
              return c + d.grossWeight;
            }, 0),
          totalVolumeWeight:
            a.totalVolumeWeight +
            b.sttPieces.reduce((c: any, d: any) => {
              return c + d.volumeWeight;
            }, 0)
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  refs = "" as any;

  onResetForm() {
    this.form.sttNumber = "";
    this.isErrorTyping = false;
    this.isErrorValidationScan = false;
    this.isValidateScan = false;
    // re focus
    const inputStt: any = this.refs.inputStt;
    inputStt?.focus();
  }

  // is disable
  get isDisabled(): boolean {
    return (
      !this.form.driverName ||
      !this.form.licensePlate ||
      !this.form.pickupDate ||
      !this.form.sttNumber ||
      this.totalStt >= 500
    );
  }

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  isFailed = false;
  get sttFailedUpdate() {
    return PickupManifestController.sttFailedUpdate;
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: this.$t("Pembuatan Pickup Manifest Berhasil !"),
        message: this.$t("Pickup Manifest berhasil dibuat."),
        image: "image-modal-success"
      };
    // if error typing not found
    if (this.isErrorTyping)
      return {
        onClick: () => this.onResetForm(),
        title: this.$t("Tambah No. STT Gagal"),
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    if (this.isErrorValidationScan)
      return {
        onClick: () => this.onResetForm(),
        title: this.$t("Scan No. STT Gagal"),
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if error typing not found
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        title: this.$t("Pembuatan Pickup Manifest Berhasil !"),
        message: this.$t(
          "Beberapa No. STT berhasil dibuat menjadi Pickup Manifest, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan."
        ),
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => (this.isFailed = false),
      onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
      title: this.$t("Pembuatan Pickup Manifest Gagal !"),
      message: this.$t("Pickup Manifest gagal dibuat."),
      image: "image-modal-failed"
    };
  }
  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "pickup_manifest_stt_failed.csv",
      listStt: data
    });
  }

  // form model
  form = {
    handoverName: "",
    driverName: "" as any,
    licensePlate: "" as any,
    pickupDate: new Date(),
    sttNumber: ""
  };
  get originCity() {
    return `${this.detailDistrict.cityCode} ${this.detailDistrict.cityName}`;
  }
  get namePOS() {
    return `${this.dataProfile.account_type_detail.company_name} [${this
      .dataProfile.account_type_detail.partnerLocation?.district_code || ""}]`;
  }

  changeError(val: boolean) {
    this.fetchVehicles("");
    VehicleController.setError(val);
  }

  // driver
  get driverOptions() {
    return PickupManifestController.driverList;
  }
  onCreatedDriver() {
    this.fetchDrivers();
  }
  fetchDrivers() {
    return PickupManifestController.getDrivers();
  }

  // handover
  get handoverOptions() {
    return [
      {
        id: "JNE",
        name: "JNE"
      },
      {
        id: "Ninja",
        name: "Ninja"
      },
      {
        id: "Consolidator CGK",
        name: "Consolidator CGK"
      },
      {
        id: "Subconsolidator CGK",
        name: "Subconsolidator CGK"
      }
    ];
  }

  // license plate
  fetchVehicles = debounce((search: string) => {
    if (search.length >= 3 || !search) {
      VehicleController.getVehicles({ search: search });
    }
  }, 250);

  get licensePlateOptions() {
    return VehicleController.vehicles.data.map(item => {
      return {
        id: item.vehicleId,
        name: item.vehicleLicensePlate
      };
    });
  }

  onCreatedLicensePlate(value: string) {
    VehicleController.createNewVehicle({ license_plate: value });
    this.fetchVehicles("");
  }

  get isLoading() {
    return VehicleController.isLoading;
  }

  // radio button
  inputSttRadioValue = "scan";
  onChangeRadio(val: string) {
    if (val === this.inputSttRadioValue) {
      this.sourceSttBkdListFromLocalStorage = [];
      return;
    }

    PickupManifestController.setSttListBkd(
      new SttListChecklistEntities(new Pagination(1, 500), [])
    );
    this.searchValue = "";
    this.selectedProduct = [];
    this.sortSelected = { name: "", value: "" };
    this.periodeStart = new Date();
    PickupManifestController.setErrorCause("");
  }

  // filter list bkd
  // filter Search
  searchValue = "";
  onSearch(value: string) {
    this.searchValue = value;
  }
  clearSearch() {
    this.searchValue = "";
  }
  // filter product
  get productOptions() {
    return BookingController.productsDelivery.data.map((e: any) => ({
      id: e.id,
      name: e.name,
      code: e.name,
      status: e.status
    }));
  }
  selectedProduct = [];
  // filter sort
  sortOptions = [
    { name: "Terbaru", value: "DESC" },
    { name: "Terlama", value: "ASC" }
  ];
  sortSelected = { name: "", value: "" };
  isProductExpand = false;

  // filter date
  periodeStart = new Date();
  minDatePeriodeStart = new Date(pastDateFromToday(30));

  onGetListBkd() {
    if (this.isDisabledSearchButton) {
      return;
    }
    PickupManifestController.getSttListChecklist({
      page: 1,
      limit: 500,
      status: "BKD,STT ADJUSTED",
      date: this.periodeStart.toLocaleDateString("fr-CA"),
      productType: this.selectedProduct.join("."),
      sortBy: this.sortSelected.value,
      cache: true
    });
  }

  get isLoadingListBkd() {
    return PickupManifestController.isLoading;
  }

  get errorCauseListBkd() {
    return PickupManifestController.errorCause;
  }

  get isDisabledSearchButton() {
    return (
      this.isLoadingListBkd ||
      !this.form.driverName ||
      !this.form.licensePlate ||
      !this.form.pickupDate ||
      !this.periodeStart
    );
  }

  // column list bkd
  get listBkd() {
    return PickupManifestController.sttListBkd?.data;
  }
  get filteredListBkd() {
    return this.listBkd.filter(
      item =>
        item.sttNumber.includes(this.searchValue) ||
        item.sttNumberElexys.includes(this.searchValue) ||
        item.sttNoRefExternal.includes(this.searchValue)
    );
  }

  // panel pickup
  isVisibleProcess = false;
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification = new STTPiecesDetail(
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    0,
    0,
    0
  );

  // table
  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("pickup-manifest") || "[]"
  );

  sourceSttBkdListFromLocalStorage: any = JSON.parse(
    localStorage.getItem("bkd-list") || "[]"
  );
  get dataSttToBePickup() {
    storeDatatoLocalStorage("pickup-manifest", this.sourceSttFromLocalStorage);
    storeDatatoLocalStorage("bkd-list", this.sourceSttBkdListFromLocalStorage);
    return this.inputSttRadioValue === "scan"
      ? this.sourceSttFromLocalStorage
      : this.sourceSttBkdListFromLocalStorage;
  }
  sortingSttNumber(index: number) {
    return this.dataSttToBePickup[index].sttPieces.sort((a: any, b: any) => {
      return a.piece - b.piece;
    });
  }
  get columns() {
    const columnsListBkd = [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (_: SttListChecklistData, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-nowrap text-black-lp-300",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300">${item.sttNumber}</div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-40 text-left whitespace-nowrap text-black-lp-300",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300">${
            !item.sttNoRefExternal ? "-" : item.sttNoRefExternal
          }</div>`;
        }
      },
      {
        name: this.$t("Tanggal STT"),
        styleHead: "w-48 text-left whitespace-nowrap text-black-lp-300",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">${formatDateWithoutTime(
            item.createdAt
          )}</div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-40 text-left whitespace-nowrap text-black-lp-300",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.productType}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Total Koli "),
        styleHead: "w-32 text-left whitespace-nowrap text-black-lp-300",
        styleCustom: "justify-center",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300">${item.totalPieces}</div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-40 text-left whitespace-nowrap text-black-lp-300",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.grossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-40 text-left whitespace-nowrap text-black-lp-300",
        render: (item: SttListChecklistData) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.volumeWeight,
            2
          )} Kg</div>`;
        }
      }
    ];
    const tempColumns = [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: STTToBePickup, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300">
                  ${item.sttNumber}
                </div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300">
                  ${item.sttRefNo}
                </div>`;
        }
      },
      {
        name: this.$t("Tanggal STT"),
        styleHead: "w-48 text-left whitespace-nowrap text-black-lp-300",
        render: (item: STTToBePickup) =>
          `<div class="text-black-lp-300 whitespace-nowrap">${formatDateWithoutTime(
            item.createdAt
          )}</div>`
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-32 text-left whitespace-nowrap",
        styleCustom: "justify-center",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300">
                  ${item.totalPiece}
                </div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300">
                  ${item.grossWeight} Kg
                </div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300">
                  ${convertDecimalWithComma(item.volumeWeight, 2)} Kg
                </div>`;
        }
      },
      {
        name: this.$t("Dest."),
        styleHead: "w-32 text-left whitespace-nowrap",
        render: (item: STTToBePickup) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destination}
                  </div>
                </div>`;
        }
      }
    ];
    if (this.inputSttRadioValue === "list") {
      return columnsListBkd;
    }
    return tempColumns;
  }

  get pickupManifestId() {
    return PickupManifestController.pickupManifestId;
  }

  get printDataList() {
    return PickupManifestController.printDataList;
  }

  // on pickup
  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  async onSave() {
    // to do logic pickup
    this.handleConfirmationSave(false);
    const resp: ResponsePayload = await PickupManifestController.updatePickup({
      originCityId: this.dataProfile.account_type_detail.partnerLocation
        ?.district_code,
      partnerId: this.dataProfile.account_type_detail.partnerLocation
        ?.partner_id,
      driverId: this.form.driverName.driverId,
      vehicleId: this.form.licensePlate.id,
      sttTobePickup: this.dataSttToBePickup.map((item: any) => {
        return item.sttNumber;
      })
    });
    if (resp.data) {
      await this.mappingResponse(resp);
    }
    this.handleConfirmationSave(false);
  }

  async mappingResponse(resp: any) {
    if (resp.data.total_stt_failed > 0 && resp.data.total_stt_success === 0) {
      this.countFailed = resp.data.total_stt_failed;
      this.isFailed = true;
      PickupManifestController.setSttFailedUpdate(resp.data.stt_failed);
    } else if (
      resp.data.total_stt_failed > 0 &&
      resp.data.total_stt_success > 0
    ) {
      this.countFailed = resp.data.total_stt_failed;
      this.countSuccess = resp.data.total_stt_success;
      PickupManifestController.setSttFailedUpdate(resp.data.stt_failed);
      this.isPartial = true;
    } else {
      this.openSuccess = true;
      if (this.withPrint) {
        await this.printManifest(resp);
      }
    }
    this.sourceSttFromLocalStorage = [];
    this.sourceSttBkdListFromLocalStorage = [];
  }

  async printManifest(resp: any) {
    MainAppController.showLoading();
    const isDetailData = await PickupManifestController.getPrintDataList({
      id: resp.pickupManifestId
    });
    if (isDetailData) {
      const detailData = PickupManifestController.printDataList;
      const data = new SectionManifestData({
        featureName: "Pickup Manifest",
        headerDetailData: new HeaderManifestData({
          code: detailData.pickupManifestCode,
          posName: ellipsisString(detailData.pickupManifestPartnerName, 25),
          originCity: `${detailData.pickupManifestOriginCityCode} - ${detailData.pickupManifestOriginCityName}`,
          createdAt: dateToDateString(detailData.pickupManifestPickupAt),
          vendorName: detailData.pickupManifestDestinationPartnerName
            ? ellipsisString(
                detailData.pickupManifestDestinationPartnerName,
                25
              )
            : detailData.pickupManifestVendorCode,
          driverName: detailData.pickupManifestDriverName,
          driverPhone: detailData.pickupManifestDriverPhone,
          vehicleNumber: detailData.pickupManifestVehiclePoliceNumber,
          totalStt: detailData.pickupManifestTotalStt,
          totalPiece: detailData.pickupManifestTotalPiece,
          totalGrossWeight: detailData.pickupManifestTotalGrossWeight,
          totalVolumeWeight: detailData.pickupManifestTotalVolumeWeight
        }),
        headerSection: [
          "posName",
          "originCity",
          "createdAt",
          "vendorName",
          "driverName",
          "driverPhone",
          "vehicleNumber",
          "totalStt",
          "totalPiece",
          "totalGrossWeight",
          "totalVolumeWeight"
        ],
        data: detailData.pickupManifestDetail.map((e: any) => {
          return {
            sttNo: e.stt_no,
            vendorName: detailData.pickupManifestPartnerName,
            productType: e.stt_product_type,
            createdAt: dateToDateString(e.stt_booked_at, true),
            totalPiece: e.stt_total_piece,
            totalGrossWeight: `${convertDecimal(
              Number(convertDecimalAfterComma(e.stt_gross_weight, 2))
            )} Kg`,
            totalVolumeWeight: `${convertDecimal(
              Number(convertDecimalAfterComma(e.stt_volume_weight, 2))
            )} Kg`,
            originCity: e.stt_origin_city_id,
            destinationCity: e.stt_destination_city_id,
            notes: ""
          };
        }),
        signature: true
      });
      if (detailData.pickupManifestCode) {
        JsBarcode(
          "#form-pickup-manifest-barcode",
          detailData.pickupManifestCode,
          {
            textAlign: "left",
            displayValue: false,
            fontSize: 14
          }
        );
      }

      const doc: any = document;
      const barcode: any = doc.getElementById("form-pickup-manifest-barcode")
        .src;

      const qrcode = await QRCode.toDataURL(
        detailData.pickupManifestCode || "",
        { errorCorrectionLevel: "Q" }
      ).then((url: string) => {
        return url;
      });

      generateManifest2ColumnPotrait(data, barcode, qrcode);
    }
    MainAppController.closeLoading();
  }

  get isErrorPickup() {
    return PickupManifestController.isErrorUpdate;
  }

  get errorCausePickup() {
    return PickupManifestController.isErrorCauseUpdate;
  }

  closeModalErrorPickup() {
    this.openConfirmationSave = false;
    PickupManifestController.setErrorUpdate(false);
  }

  pickupAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  openSuccess = false;
  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  get hasChanged() {
    return (
      this.form.driverName ||
      this.form.licensePlate ||
      this.dataSttToBePickup.length > 0
    );
  }
  goBack() {
    this.$router.push("/pickup-manifest");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("pickup-manifest");
    removeDataLocalStorage("bkd-list");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }
  get errorWordingNotFoundOrDeleted() {
    return this.$t("No. STT tidak ditemukan/sudah terhapus");
  }
  get errorWordingAlreadyInput() {
    return this.$t("No. STT sudah di input");
  }
}
