import {
  dateToDateString,
  dateToTime,
  ellipsisString,
  firstCapitalize,
  roundingDecimal
} from "@/app/infrastructures/misc/Utils";
import { PrintDataList } from "@/domain/entities/PickupManifest";
import jsPDF from "jspdf";
import { AccountController } from "@/app/ui/controllers/AccountController";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";

const generateLabelPDF = (
  data: PrintDataList,
  logo: string,
  barcode: string
) => {
  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  const translate = i18nInstance.global.t;
  const doc: any = new jsPDF("p", "mm", "a6", true);
  doc.addImage(logo, "PNG", 2, 4, 28, 6, "logo", "FAST", 0);
  doc.addImage(barcode, "PNG", 35, 2, 45, 10, "barcode", "FAST", 0);
  doc.setDrawColor(224, 224, 224);
  doc.setLineWidth(0.3);
  doc.line(0, 14, 110, 14);
  doc.setLineWidth(0.3);
  doc.line(0, 132, 110, 132);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("ID Manifest"), 6, 25);
  doc.text(":", 50, 25);
  doc.setFont("helvetica", "bold");
  doc.text(ellipsisString(data.pickupManifestCode || "", 24), 52, 25);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Nama POS"), 6, 34);
  doc.text(":", 50, 34);
  doc.setFont("helvetica", "bold");
  doc.text(ellipsisString(data.pickupManifestPartnerName, 24), 52, 34);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Kota Asal"), 6, 43);
  doc.text(":", 50, 43);
  doc.setFont("helvetica", "bold");
  doc.text(
    `${data.pickupManifestOriginCityCode} - ${firstCapitalize(
      data.pickupManifestOriginCityName.toLowerCase()
    )}`,
    52,
    43
  );

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Tanggal Pickup"), 6, 52);
  doc.text(":", 50, 52);
  doc.setFont("helvetica", "bold");
  doc.text(
    `${dateToDateString(new Date(data.pickupManifestPickupAt))}, ${dateToTime(
      new Date(data.pickupManifestPickupAt)
    )} WIB`,
    52,
    52
  );

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Nama Vendor/Partner"), 6, 61);
  doc.text(":", 50, 61);
  doc.setFont("helvetica", "bold");
  doc.text(
    ellipsisString(data.pickupManifestDestinationPartnerName, 24),
    52,
    61
  );

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Nama Driver"), 6, 70);
  doc.text(":", 50, 70);
  doc.setFont("helvetica", "bold");
  doc.text(data.pickupManifestDriverName, 52, 70);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("No. Telp Driver"), 6, 79);
  doc.text(":", 50, 79);
  doc.setFont("helvetica", "bold");
  doc.text(data.pickupManifestDriverPhone, 52, 79);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("No. Seri Kendaraan"), 6, 88);
  doc.text(":", 50, 88);
  doc.setFont("helvetica", "bold");
  doc.text(data.pickupManifestVehiclePoliceNumber, 52, 88);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Total STT"), 6, 97);
  doc.text(":", 50, 97);
  doc.setFont("helvetica", "bold");
  doc.text(String(data.pickupManifestTotalStt), 52, 97);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Total Koli"), 6, 106);
  doc.text(":", 50, 106);
  doc.setFont("helvetica", "bold");
  doc.text(String(data.pickupManifestTotalPiece), 52, 106);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Total Berat Kotor"), 6, 115);
  doc.text(":", 50, 115);
  doc.setFont("helvetica", "bold");
  doc.text(String(data.pickupManifestTotalGrossWeight) + " Kg", 52, 115);

  doc.setFontSize(9);
  doc.setTextColor(51, 51, 51);
  doc.setFont("helvetica", "normal");
  doc.text(translate("Total Berat Dimensi"), 6, 124);
  doc.text(":", 50, 124);
  doc.setFont("helvetica", "bold");
  doc.text(
    String(roundingDecimal(data.pickupManifestTotalVolumeWeight)) + " Kg",
    52,
    124
  );

  doc.setFontSize(6.5);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(51, 51, 51);
  doc.text(
    translate(
      "Terjadi Kendala Pada Paket Kiriman Anda ? Hubungi Tim Klaim Kami Melalui"
    ),
    3,
    138
  );

  doc.setFontSize(6);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(209, 31, 64);
  doc.text("www.lionparcel.com/claim", 3, 144.5);

  doc.setFontSize(6);
  doc.setTextColor(209, 31, 64);
  doc.setFont("helvetica", "normal");
  doc.text(`${translate("S&K")} : lionparcel.com/sk`, 79, 144.5);

  doc.setProperties({
    title: "Label Manifest"
  });

  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default generateLabelPDF;
