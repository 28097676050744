/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { VehiclePresenter } from "../presenters/VehiclePresenter";
import { VehicleRequest } from "@/data/payload/api/VehicleRequest";
import { Vehicles } from "@/domain/entities/Vehicle";
export interface VehicleState {
  errCause: string;
  err: boolean;
  isLoading: boolean;
  vehicles: Vehicles;
}
@Module({ namespaced: true, dynamic: true, store, name: "vehicle" })
class VehicleStore extends VuexModule implements VehicleState {
  public vehicles: Vehicles = new Vehicles([]);
  public errCause = "";
  public err = false;
  public isLoading = false;

  @Action
  public createNewVehicle(params: { license_plate: string }) {
    this.setLoading(true);
    const presenter = container.resolve(VehiclePresenter);
    presenter
      .createNewVehicle(new VehicleRequest(params.license_plate))
      .then(() => {
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getVehicles(params: { search: string }) {
    this.setLoading(true);
    const presenter = container.resolve(VehiclePresenter);
    presenter
      .getVehicles(params.search)
      .then((res: Vehicles) => {
        this.setVehicles(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setErrorCause(str: string) {
    this.errCause = str;
  }

  @Mutation
  public setError(err: boolean) {
    this.err = err;
  }

  @Mutation
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  public setVehicles(vehicles: Vehicles) {
    this.vehicles = vehicles;
  }
}

export const VehicleController = getModule(VehicleStore);
