/* eslint-disable @typescript-eslint/camelcase */
import { VehicleRequestInterface } from "../contracts/VehicleRequest";

export class VehicleRequest implements VehicleRequestInterface {
  private licensePlate: string;

  constructor(licensePlate: string) {
    this.licensePlate = licensePlate;
  }

  public toJSON(): string {
    return JSON.stringify({
      license_plate: this.licensePlate
    });
  }
}
