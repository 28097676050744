
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import convertDecimalAfterComma from "@/app/infrastructures/misc/common-library/ConvertDecimalAfterComma";
import convertDecimal from "@/app/infrastructures/misc/common-library/ConvertDecimal";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PickupManifestController } from "@/app/ui/controllers/PickupManifestController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import generateLabelPDF from "./modules/label-pdf";
import { OUTGOING_SHIPMENT_PICKUP_MANIFEST } from "@/app/infrastructures/misc/RolePermission";
import {
  PickupManifestData,
  PrintDataList
} from "@/domain/entities/PickupManifest";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import { SectionManifestData, HeaderManifestData } from "@/domain/entities/MainApp";
import { generateManifest2ColumnPotrait } from "./modules/manifest-pdf-v3";

@Options({
  components: {},
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(OUTGOING_SHIPMENT_PICKUP_MANIFEST.CREATE);
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class PickupManifest extends Vue {
  fetchPickupManifestList() {
    PickupManifestController.fetchPickupManifestList();
  }

  get validationAccount() {
    return AccountController.validationAccount;
  }

  // loading
  get isLoading() {
    return PickupManifestController.isLoading;
  }

  // error cause
  get errorCause() {
    return PickupManifestController.errorCause;
  }

  // filter date
  get periodeStart() {
    return PickupManifestController.periodeStart;
  }

  get periodeEnd() {
    return PickupManifestController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    PickupManifestController.setPeriodeStart(value[0]);
    PickupManifestController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchPickupManifestList();
  }

  // goToCreate
  goToCreate() {
    this.$router.push("/pickup-manifest/create");
  }

  // Table

  get account() {
    return AccountController.accountData;
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (_: any, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: this.$t("ID Manifest"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.pickupManifestCode}</div>`;
        }
      },
      {
        name: this.$t("Nama POS"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.pickupManifestPartnerName,
            83
          )}</div>`;
        }
      },
      {
        name: this.$t("Nama Vendor/Partner"),
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.pickupManifestDestinationPartnerName !== ""
              ? item.pickupManifestDestinationPartnerName
              : item.pickupManifestDestinationVendorCode,
            80
          )}</div>`;
        }
      },
      {
        name: this.$t("Tanggal Pickup"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.pickupManifestPickupAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.pickupManifestCreatedName
          }</div></div>`;
        }
      },
      {
        name: this.$t("Nama Driver"),
        styleHead: "w-52 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="flex flex-col truncate">
          <p class="truncate text-left w-40">${item.pickupManifestDriverName}</p>
          <div class="truncate text-left w-36">(${item.pickupManifestDriverPhone})</div>
          </div>`;
        }
      },
      {
        name: this.$t("No. Seri Kendaraan"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.pickupManifestVehiclePoliceNumber}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.pickupManifestTotalStt}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${item.pickupManifestTotalPieces}</div>`;
        }
      },
      {
        name: this.$t("Total Gross Weight"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.pickupManifestTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Total Volume Weight"),
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: PickupManifestData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.pickupManifestTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-50 text-left",
        styleButton: (item: any, index: any) => {
          return {
            outline: true,
            icon: "chevron-up",
            title: "Print",
            textColor: "black",
            color: "gray-lp-400",
            small: false,
            customClass: "print-button-padding",
            groupOptionsClass: "pickup-manifest-group-options",
            class: `w-full icon-chevron-up ${
              this.chevronUp[index] ? "" : "icon-chevron-down"
            }`,
            clickFunction: () => {
              this.chevronUp[index] = !this.chevronUp[index];
            },
            groupOptions: [
              {
                icon: "printer",
                label: this.$t("Print PDF"),
                clickFunction: this.printManifest
              },
              {
                icon: "invoice-gray",
                label: "Print Label",
                clickFunction: this.onPrintLabel
              }
            ]
          };
        }
      }
    ];
  }

  chevronUp: boolean[] = [];

  async onPrintLabel(item: any) {
    MainAppController.showLoading();
    const printLabel = await this.fetchPrintDataList(item.pickupManifestId);
    if (printLabel) {
      const printData = this.printDataList;
      JsBarcode(
        "#list-pickup-manifest-barcode",
        printData.pickupManifestCode || "",
        {
          textAlign: "left",
          displayValue: false,
          fontSize: 14
        }
      );
      const doc: any = document;
      const logo: any = doc.getElementById("lionparcel").src;
      const barcode: any = doc.getElementById("list-pickup-manifest-barcode")
        .src;
      generateLabelPDF(printData, logo, barcode);
    }
    MainAppController.closeLoading();
  }

  get pagination() {
    return PickupManifestController.pickupManifestList.pagination;
  }

  get pickupManifestList() {
    const data = PickupManifestController.pickupManifestList.data;
    this.chevronUp = data.map(() => false);
    return data;
  }

  async fetchPrintDataList(id: number) {
    return PickupManifestController.getPrintDataList({
      id: id
    });
  }

  get printDataList(): PrintDataList {
    return PickupManifestController.printDataList;
  }

  keepObjectPrintPickupManifest = {};
  printManifestTryLoad() {
    this.printManifest(this.keepObjectPrintPickupManifest);
  }

  check = null;
  async printManifest(item: any) {
    MainAppController.showLoading();
    this.keepObjectPrintPickupManifest = item;
    const printResult = await this.fetchPrintDataList(item.pickupManifestId);

    if (printResult) {
      const dataManifest: PrintDataList = this.printDataList;
      const data = new SectionManifestData({
          featureName: "Pickup Manifest",
          headerDetailData: new HeaderManifestData({
            code: dataManifest.pickupManifestCode,
            posName: ellipsisString(dataManifest.pickupManifestPartnerName, 25),
            originCity: `${dataManifest.pickupManifestOriginCityCode} - ${dataManifest.pickupManifestOriginCityName}`,
            createdAt: dateToDateString(dataManifest.pickupManifestPickupAt),
            vendorName: dataManifest.pickupManifestDestinationPartnerName ? ellipsisString(dataManifest.pickupManifestDestinationPartnerName, 25) : dataManifest.pickupManifestVendorCode,
            driverName: dataManifest.pickupManifestDriverName,
            driverPhone: dataManifest.pickupManifestDriverPhone,
            vehicleNumber: dataManifest.pickupManifestVehiclePoliceNumber,
            totalStt: dataManifest.pickupManifestTotalStt,
            totalPiece: dataManifest.pickupManifestTotalPiece,
            totalGrossWeight: dataManifest.pickupManifestTotalGrossWeight,
            totalVolumeWeight: dataManifest.pickupManifestTotalVolumeWeight
        }),
        headerSection: [
          'posName',
          'originCity',
          'createdAt',
          'vendorName',
          'driverName',
          'driverPhone',
          'vehicleNumber',
          'totalStt',
          'totalPiece',
          'totalGrossWeight',
          'totalVolumeWeight'
        ],
        data: dataManifest.pickupManifestDetail.map((e: any) => {
          return {
            sttNo: e.stt_no,
            vendorName: dataManifest.pickupManifestPartnerName,
            productType: e.stt_product_type,
            createdAt: dateToDateString(e.stt_booked_at, true),
            totalPiece: e.stt_total_piece,
            totalGrossWeight: `${convertDecimal(
              Number(convertDecimalAfterComma(e.stt_gross_weight, 2))
            )} Kg`,
            totalVolumeWeight: `${convertDecimal(
              Number(convertDecimalAfterComma(e.stt_volume_weight, 2))
            )} Kg`,
            originCity: e.stt_origin_city_id,
            destinationCity: e.stt_destination_city_id,
            notes: ""
          }
        }),
        signature: true
      });

      JsBarcode(
        "#list-pickup-manifest-barcode",
        dataManifest.pickupManifestCode || "",
        {
          textAlign: "left",
          displayValue: false,
          fontSize: 14
        }
      );
      const doc: any = document;
      const barcode: any = doc.getElementById("list-pickup-manifest-barcode").src;
      const qrcode = await QRCode.toDataURL(dataManifest.pickupManifestCode || "", { errorCorrectionLevel: "Q" }).then(
        (url: string) => {
          return url;
        }
      );
      generateManifest2ColumnPotrait(data, barcode, qrcode);
    }
    MainAppController.closeLoading();
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get isLoadingPrintList() {
    return PickupManifestController.isLoadingPrintDataList;
  }

  get isErrorPrintList() {
    return PickupManifestController.isErrorPrintList;
  }

  get errorCausePrintList() {
    return PickupManifestController.isErrorCausePrintList;
  }

  closeModalErrorPrintList() {
    PickupManifestController.setErrorPrintList(false);
  }
}
